import React, { useState } from "react";
import { Col, Container, Row, Toast } from "react-bootstrap";
import { Images } from "../Assets/images/images";
import ContactFooter from "../components/ContactFooter";
import Layout from "../components/layout";
import "../Assets/styles/contact.scss";
import { graphql } from "gatsby";
import { imagePathURL } from "../utils/JSONData";
import { Fade } from "react-reveal";
import Scrolltop from "../components/Scrolltotop/scrolltop";
// import { token } from '../../gatsby-node'

const ContactPage = () => {

  const data = {
    allHowtoproceeds: {
      nodes: [
        {
          data: [
            {
              attributes: {
                title: "contact",
                description: "Please share your business requirements. Our process for corporate privacy is transparent and open to signing an NDA."
              },
            },
            {
              attributes: {
                title: "Analysis",
                description: "Our consultant will closely work with you for an in-depth discussion of your business and will decide the optimal business solution."
              },
            },
            {
              attributes: {
                title: "Proposal",
                description: "We will submit our technical and commercial proposal upon completion of the analysis, followed by a proposal walkthrough for clarification or modification of the proposal."
              },
            }
          ]
        }
      ]
    }
  }

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    country: "",
    message: "",
  })

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    number: "",
    country: "",
    message: "",
  });

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const errors = {};
      if (!formData.name) {
        errors.name = "*Please enter your name.";
      }
      if (!formData.email) {
        errors.email = "*Please enter your email.";
      } else {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!emailRegex.test(formData.email)) {
          errors.email = "*Please enter a valid email address.";
        }
      }
      if (!formData.number) {
        errors.number = "*Please enter your phone number.";
      }
      if (!formData.country) {
        errors.country = "*Please select a country.";
      }
      if (!formData.message) {
        errors.message = "*Please enter a message.";
      }

      setValidationErrors(errors);

      if (Object.keys(errors).length > 0) {
        // showCustomToast("Please fix the form errors", "error");
        return;
      }
      const data = {
        data: {
          name: formData.name,
          email: formData.email,
          country: formData.country,
          message: formData.message,
          number: formData.number,
        }
      }
      // console.log("data", token);
      // console.log('data',data)
      console.log("formData", formData)

      const response = await fetch("https://api.madhavtechno.com/api/contact-uses", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "3780cd8c5cd8c5cb1aaa6efeb15740a0c4e94ac77e1d9992045af81d82b597b93441545eef7d653d3c02721d4b96b6121dd82107e3eb01b71b731a8df0d96f57",
        },
        body: JSON.stringify(data),
      });
      console.log("response", response)
      if (response.ok) {
        const responseData = await response.json();
        console.log("API response data:", responseData);
        setFormData({
          name: "",
          email: "",
          number: "",
          country: "",
          message: "",
        });
        showCustomToast("submitted successfully", "success");
      } else {
        console.error("API request failed with status:", response.status);
        showCustomToast("Failed to submit the form", "error");
      }
    } catch (error) {
      console.log("Error", error)
      showCustomToast("An error occurred while submitting the form", "error");
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const showCustomToast = (message, type) => {
    setShowToast(true);
    setToastMessage(message);
    setToastType(type);
  };

  // const { allSchedulecall2, allHowtoproceeds, allGetStarted } = data
  return (
    <Layout>
      <div className="contact_bg1">
        <img src={Images.contactBg1} alt="conatct" className="contact_bg-first" />
        <div className="contact_main_content">
          <img className="contact_blueDotImg" src={Images.blueDotImg} alt="blue-dot" />
          <div className="contact_started_form">
            <Container>
              <form onSubmit={handleSubmit}>
                {/* <div className="text_style">{allGetStarted.nodes[0].data.attributes.title}</div> */}
                <div className="text_style">Let's get started</div>
                {/* <div className="contact_form_sub_text">{allGetStarted.nodes[0].data.attributes.description}</div> */}
                <div className="contact_form_sub_text">Thank you for your interest in our services. Please fill out the form below and we will get back to you promptly regarding your request.</div>
                <Row>
                  <Col xs={12} md={5} className="mr-20">
                    <div className="contact_form_group">
                      <input
                        type="text"
                        className="input-field"
                        // placeholder="Your Name"
                        name="name"
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                      // required
                      />
                      <label className="input-label" htmlFor="name">Your Name</label>
                      <div className="error-message">{validationErrors.name}</div>
                    </div>
                  </Col>
                  <Col xs={12} md={5}>
                    <div className="contact_form_group">
                      <input
                        type="text"
                        className="input-field"
                        // placeholder="Email address"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        name="email"
                      // required
                      />
                      <label className="input-label" htmlFor="email">Email address</label>
                      <div className="error-message">{validationErrors.email}</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={5} className="mr-20">
                    <div className="contact_form_group">
                      <input
                        type="text"
                        className="input-field"
                        // placeholder="Phone number"
                        name="number"
                        id="number"
                        value={formData.number}
                        onChange={handleChange}
                      // required
                      />
                      <label className="input-label" htmlFor="number">Phone number</label>
                      <div className="error-message">{validationErrors.number}</div>
                    </div>
                  </Col>
                  <Col xs={12} md={5}>
                    <div className="contact_form_group">
                      <input
                        type="text"
                        className="input-field"
                        // placeholder="Select country"
                        id="country"
                        value={formData.country}
                        onChange={handleChange}
                        name="country"
                      // required
                      />
                      <label className="input-label" htmlFor="country">Enter country</label>
                      <div className="error-message">{validationErrors.country}</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={11}>
                    <div className="contact_form_group">
                      <textarea
                        className="input-field form_control_textarea"
                        name="message"
                        // placeholder="Your Message"
                        id="message"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                      <label className="input-label" htmlFor="message">Your Message</label>
                      <div className="error-message">{validationErrors.message}</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      <button className="contact_button" type="submit">SUBMIT
                        <span>
                          <img src={Images.WhiteArrow} alt="white arrow"></img>
                        </span></button>
                    </div>
                  </Col>
                </Row>
              </form>
            </Container>
          </div>
        </div>
        <div className="contact_proceed_view">
          <img className="contact_whiteDotImg" src={Images.whiteDotImg} alt="white-dot" />
          <div className="contact_proceed_header_text">
            <span style={{ display: "inline-block" }}>
              How to Proceed
              <div style={{ display: "flex", justifyContent: "center" }} >
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "25%", marginRight: "10px" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "3%" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "25%", marginLeft: "10px" }}> </div>
              </div>
            </span>
          </div>
          <Container>
            <Row className="contact_row">
              {data.allHowtoproceeds.nodes[0].data.map((item, i) => {
                return <Col xs={12} md={12} lg={6} className="" key={i}>
                  <Fade left>
                    <div className='contact_block_group bg01' style={{ backgroundImage: `url(${imagePathURL + '/uploads/'})` }}>
                      <div className="contact_block_title">{item.attributes.title}</div>
                      <div className="contact_block_subcontent">
                        {item.attributes.description}
                      </div>
                    </div>
                  </Fade>
                </Col>
              })}
            </Row>
            <div className="contact_schedule">
              <button className="contact_button contact_schedule_button" onClick={() => window.open('https://calendly.com/hiteshkachhadiya', '_blank')} >
                {/* {allSchedulecall2.nodes[0].data.attributes.btnTxt} */}
                SCHEDULE A CALL NOW!
              </button>
            </div>
            <div className="contact_block_subcontent contact_proceed_text">
              {/* {allSchedulecall2.nodes[0].data.attributes.description} */}
              Schedule an intro call with us. We'll answer your questions, scope your project and discuss potential fit.
            </div>
          </Container>
        </div>
      </div>
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
        className={`custom-toast ${toastType}`}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
      <ContactFooter />
      <Scrolltop />
    </Layout>
  );
};
// export const query = graphql`
//   {
//     allHowtoproceeds {
//       nodes {
//         data {
//           attributes {
//             title
//             description
//             image {
//               data {
//                 attributes {
//                   url
//                 }
//               }
//             }
//           }
//         }
//       }
//     }

//     allSchedulecall2 {
//       nodes {
//         data {
//           attributes {
//             description
//             btnTxt
//           }
//         }
//       }
//     }

//     allGetStarted {
//       nodes {
//         data {
//           attributes {
//             title
//             description
//           }
//         }
//       }
//     }
//   }
// `;
export default ContactPage;
